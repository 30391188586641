import React from "react";

export function getUser(){
    return JSON.parse(localStorage.getItem('user'))
}

export function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

export function getDate(d){
    const year = d.getFullYear() 
    const date1 = d.getDate()
    const monthIndex = d.getMonth()
    const monthName = months[monthIndex]
    const formatted = `${monthName} ${date1}, ${year}`
    return formatted
}

export function getDateTime(d){
    const year = d.getFullYear() 
    const date1 = d.getDate()
    const monthIndex = d.getMonth()
    const monthName = months[monthIndex]
    const time =  d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    const formatted = `${monthName} ${date1}, ${year} ${time}`
    return formatted
}

export function getAmPmHour(hr){
    switch (hr){
        case 6:
            return '6 AM'
        case 7:
            return '7 AM'
        case 8:
            return '8 AM'
        case 9:
            return '9 AM'
        case 10:
            return '10 AM'
        case 11:
            return '11 AM'
        case 12:
            return '12 PM'
        case 13:
            return '1 PM'
        case 14:
            return '2 PM'
        case 15:
            return '3 PM'
        case 16:
            return '4 PM'
        case 17:
            return '5 PM'
        case 18:
            return '6 PM'
        case 19:
            return '7 PM'
        case 20:
            return '8 PM'
        case 21:
            return '9 PM'
        case 22:
            return '10 PM'
        case 23:
            return '11 PM'

        default:
          return
      }
}

export function getRndPanel(panels) {
    const index = Math.floor(Math.random() * panels.length)
    return panels[index]
  }

export const startEndTimes = [
{ hour: 6, ampm: "6 AM"},
{ hour: 7, ampm: "7 AM"},
{ hour: 8, ampm: "8 AM"},
{ hour: 9, ampm: "9 AM"},
{ hour: 10, ampm: "10 AM"},
{ hour: 11, ampm: "11 AM"},
{ hour: 12, ampm: "12 PM"},
{ hour: 13, ampm: "1 PM"},
{ hour: 14, ampm: "2 PM"},
{ hour: 15, ampm: "3 PM"},
{ hour: 16, ampm: "4 PM"},
{ hour: 17, ampm: "5 PM"},
{ hour: 18, ampm: "6 PM"},
{ hour: 19, ampm: "7 PM"},
{ hour: 20, ampm: "8 PM"},
{ hour: 21, ampm: "9 PM"},
{ hour: 22, ampm: "10 PM"},
{ hour: 23, ampm: "11 PM"},
]

export function calculateTimeLeft(answerByDate){
    let difference = +answerByDate - +new Date();
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
    };
  }
  
  return timeLeft;
  
  }

  export const ReactLazyPreload = importStatement => {
    console.log(importStatement)
    const Component = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
  };