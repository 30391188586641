import React, { Suspense, lazy } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { useUserState } from "../context/UserContext";
import { getUser } from '../utils'
// pages
import Home from '../pages/Home'
import Loading from '../pages/Loading'
const Error = lazy(() => import('../pages/error/Error'));
const Login = lazy(() => import('../pages/login/Login'));
const SignUp = lazy(() => import('../pages/login/NewUser'));
const InviteSignUp = lazy(() => import('../pages/login/InviteSignUp'));
const Subscribe = lazy(() => import('../pages/login/Subscribe'));
const Layout = lazy(() => import('./Layout/Layout'));
const LearnMore = lazy(() => import('../pages/LearnMore'));
const EnterpriseContact = lazy(() => import('../pages/EnterpriseContact'));

export default () => {

  return (
    <HashRouter>
      <Suspense fallback={<Loading  />}>
        <Switch>
          <Route
            exact
            path="/app"
            render={() => <Redirect to={`dashboard`} />}
          />
          <PublicRoute exact path="/" component={Home} />
          <PrivateRoute path="/app" component={Layout} />
          <PublicRoute path="/learn_more" component={LearnMore} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/signup" component={SignUp} />
          <PublicRoute path="/home" component={Home} />
          <PublicRoute path="/invite_sign_up/:token" component={InviteSignUp} />
          <PublicRoute path="/subscribe" component={Subscribe} />
          <PublicRoute path="/enterprise_contact" component={EnterpriseContact} />
          <Route component={Error} />
        </Switch>
      </Suspense>
    </HashRouter>
  );

  // #######################################################################

  
  function PrivateRoute({ component, ...rest }) {
    const user = getUser()
    return (
      <Route
        {...rest}
        render={props =>
          user ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: `/`,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    const user = getUser()
    const userRole = user && user.role === 'TEACHER' ? 'teacher' : 'student'
    return (
      <Route
        {...rest}
        render={props =>
          user ? (
            <Redirect
            to={{
              pathname: `/app/${userRole}_dashboard`,
              state: {
                from: props.location,
              },
            }}
          />
            ) : (
             React.createElement(component, props)
          )
        }
      />
    );
  }
}
