import React, { useState } from "react";
import { Link } from 'react-router-dom'

import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import Carousel from 'react-material-ui-carousel'

// styles
import useStyles from "./login/styles";

// logo
import quandrio_logo from "../images/quandrio-logo.png"
import add_slides from "../images/upload_slides1.png"
import answer_question from "../images/answer_question_m.png"
import create_question from "../images/create_question_m.png";
import challenge_question from "../images/challenge_question_m.png"
import monitor_performance from "../images/student_perf_m.png";
import auto_grade from "../images/autograde_m.png";
import student_notification from "../images/quandrio_notification1_m.png";

import VideoCard from "../components/VideoCard";

import { ReactLazyPreload } from '../utils'

const styles = {
   media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
   },
   card: {
      position: 'relative',
      marginTop:'10%'
   },
   overlay: {
      position: 'absolute',
      top: 60,
      left: 150,
   },
   close:{
    position: 'absolute',
    right: 10,
    top: 10,
    color: 'white'
  },
  icon:{
    height:100,
    width:100
  }
}   


const VideoIntro = ({handleClose,open}) => {

  return(
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
    <DialogTitle id="simple-dialog-title" style={{backgroundColor:'#536DFE', color:'white'}}>
      Welcome to Quandrio!
    </DialogTitle>
      <iframe
        title="intro"
        id="video"
        width="640"
        height="360"
        src={"https://www.youtube.com/embed/4q9UafsiQ6k"}
        frameBorder="0"
        allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
       <IconButton aria-label="close" style={styles.close} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
    </Dialog>
  )
}

const MainLogo = () => {
  var classes = useStyles();
  return(
    <center>
    <img src={quandrio_logo} alt="logo" className={classes.logotypeImage} />
    <Typography className={classes.logotypeText}>
      Quandrio
    </Typography>
    <Typography variant="h3" color="secondary">
      Ask a question. Answer a question.
    </Typography>
    </center>
  )
}

const UploadPhotos = () => {
  var classes = useStyles();
  return(
    <center>
    <Typography variant="h3" style={{margin:20, color:'white'}}>
      Teacher Upload Slides
    </Typography>
    <img src={add_slides} style={{borderRadius:5}} alt="logo" width="600" />
    </center>
  )
}

const AnswerQuestion = () => {
  return(
    <div >
    <center>
    <Typography variant="h3" style={{margin:20, color:'white'}}>
      Student Answers a Question
    </Typography>
    <img src={answer_question} alt="logo" height="500" />
    </center>
  </div>
  )
}

const CreateQuestion = () => {
  return(
    <div >

      <center>
      <Typography variant="h3" style={{margin:20, color:'white'}}>
        Student Creates a Question
    </Typography>
        <img src={create_question} alt="logo" height="500" />
      </center>
  </div>
  )
}

const AutoGrade = () => {
  return(
    <div >
      <center>
      <Typography variant="h3" style={{margin:20, color:'white'}}>
        Automatic Grading
      </Typography>
        <img src={auto_grade} alt="logo" height="500" />
      </center>
  </div>
  )
}

const ChallengeQuestion = () => {
  return(
    <div >
      <center>
        <Typography variant="h3" style={{margin:20, color:'white'}}>
          Student  Can Challenge a Question
        </Typography>
        <img src={challenge_question} alt="logo" height="500" />
      </center>
  </div>
  )
}

const MonitorPerformance = () => {
  return(
    <div >
      <center>
      <Typography variant="h3" style={{margin:20, color:'white'}}>
        Monitor Student Performance in Real Time
      </Typography>
        <img src={monitor_performance} alt="logo" height="500" />
      </center>
  </div>
  )
}

const StudentNotification = () => {
  return(
    <div >
      <center>
      <Typography variant="h3" style={{margin:20, color:'white'}}>
        Student Receives Notification
      </Typography>
        <img src={student_notification} alt="logo" height="500" />
      </center>
  </div>
  )
}

const loadLearnMore = () => import('../pages/LearnMore')
const loadLogin = () => import('../pages/login/Login')
const loadSignUp = () => import('../pages/login/NewUser')

export default (props) => {
  var classes = useStyles();

  const [ open, setOpen ] = useState(false)

  const toggleClose = () => setOpen(!open)

  const SignUpButton = () => {
    return(
      <Grid item>
      <Button 
        variant="contained" 
        color="primary" 
        style={{color:'white'}}
        component={Link} 
        to='/signup'
        onMouseOver={loadSignUp} > 
          Sign Up
        </Button>
    </Grid>
    )
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer} >
        <Carousel >
          <MainLogo />
          <UploadPhotos />
          <StudentNotification />
          <CreateQuestion />
          <AnswerQuestion />
          <AutoGrade />
          <ChallengeQuestion />
          <MonitorPerformance />
        </Carousel>
      </div>

      <div className={classes.formContainer}>

      <div className={classes.topLogo} >
        
          <center>
          
          <img src={quandrio_logo} alt="logo" className={classes.headerLogo} />
          <Typography style={{color:'white'}}>Quandrio</Typography>
          </center>
        </div>
     
        <div className={classes.form}>

        <Typography variant="h1" style={{marginBottom:10, color:"#3CD4A0"}}>
          Quandrio
        </Typography>

        <Divider style={{marginBottom:30}}/>

        <Typography variant="h2" style={{marginBottom:10}}>
          Engaged, 
        </Typography>
        <Typography variant="h2" style={{marginBottom:10}}>
          continous
        </Typography>
        <Typography variant="h2"  color='primary' style={{marginBottom:10}}>
          learning
        </Typography>
        <Typography variant="h2" component="h1" style={{marginBottom:30}}>
          from <span style={{fontStyle:'italic'}}>anywhere</span>.
        </Typography>

        <div style={{marginTop:20}}>
          <Grid container justify="space-between" spacing={3}>

            <Grid item xs={12}>
              <Button 
                fullWidth 
                variant="contained" 
                color="primary" 
                style={{color:'white'}}
                component={Link} 
                to='/learn_more' 
                onMouseOver={loadLearnMore}
                > 
                Learn More
              </Button>
              </Grid>

            <Grid item xs={12}>
              <Divider style={{marginBottom:30, marginTop:20}}/>
            </Grid>

            <Grid item>
              <Button               
              variant="contained" 
              color="primary" 
              style={{color:'white'}}
              component={Link} component={Link} to='/login' 
              onMouseOver={loadLogin} > 
                Sign In
              </Button>
            </Grid>

          </Grid>

          
        </div>
        </div>

        <div className={classes.smallform} style={{marginTop:30}} >
          <div style={{backgroundColor:"#3CD4A0",paddingTop:20, height:700}}>
          <Carousel >
          <MainLogo />
          <UploadPhotos />
          <StudentNotification />
          <CreateQuestion />
          <AnswerQuestion />
          <AutoGrade />
          <ChallengeQuestion />
          <MonitorPerformance />
        </Carousel>
        </div>
        </div>
        
        
        <Typography color="primary" className={classes.copyright} style={{marginTop:30}}>
          © 2020 Quandrio. All rights reserved.
        </Typography>
      </div>
      <VideoIntro open={open} handleClose={toggleClose}/>
    </Grid>
  );
}
