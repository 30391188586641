import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' 
import 'firebase/storage';
import 'firebase/storage';
import "firebase/performance";
import "firebase/analytics";
import "firebase/functions";

import { createStore, combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
 import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' 

 const firebaseConfig = {
    apiKey: "AIzaSyAU9rdHnQ8H5LcAGfX_zG8ALGbIteBRASw",
    authDomain: "quandria-7bfca.firebaseapp.com",
    databaseURL: "https://quandria-7bfca.firebaseio.com",
    projectId: "quandria-7bfca",
    storageBucket: "quandria-7bfca.appspot.com",
    messagingSenderId: "185582499906",
    appId: "1:185582499906:web:b5b7e178dbb860343055db",
    measurementId: "G-XF91BJ8JCC"
  };
  
  const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true
  }
  
  firebase.initializeApp(firebaseConfig)
  
  firebase.firestore() 
  export var functions = firebase.functions() 
  export var fb = firebase
  export var auth = firebase.auth()
  export var database = firebase.firestore()
  export var storage = firebase.storage()
  export var analytics = firebase.analytics();
  firebase.performance();
  
  const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer 
  })
  
  const initialState = {}
  const store = createStore(rootReducer, initialState)

  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance 
  }

  export { 
      store, 
      rrfProps
  }