import React, { createContext, useContext, useReducer } from "react";
import { auth, database } from '../firebase'

var UserStateContext = createContext();
var UserDispatchContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("user")
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError, setErrorMsg) {
  setError(false);
  setIsLoading(true);
  setErrorMsg('')

  auth.signInWithEmailAndPassword(login, password)
    .then(result => {

      const { uid } = result.user

      const usersRef = database.collection('users')
      
      usersRef.doc(uid).get()
      .then(doc => {
        if (doc.exists) {
          const user = doc.data()

          localStorage.setItem('user', JSON.stringify(user))
          const userRole = user.role === 'TEACHER' ? 'teacher' : 'student'
          setError(null)
          setIsLoading(false)
          dispatch({ type: 'LOGIN_SUCCESS' })

          if (user.role==='TEACHER'){
              history.push('/app/teacher_dashboard')
          }

          if (user.role==='STUDENT'){
            history.push('/app/student_dashboard')
          }
          
        } else {
            setError(true)
            setErrorMsg('Login error!')
        }
    
      })
    
    })
    .catch(err => {
      setError(true);
      setErrorMsg(err.message)
      setIsLoading(false);
    })
}

function signOut(dispatch, history) {
  auth.signOut()
  .then(res => { 
  })
  .catch(err => { 
    console.log(err)
  })
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  localStorage.removeItem('user')
  history.push("/login");
}
